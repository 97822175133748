import axios from "axios";
import { API_URL } from "./config.js";

export const apiCall = (url, method, data) => {

    let solid_id = 0;

    // console.log("solid_id", solid_id);

    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) {
        solid_id = JSON.parse(localStorage.getItem("user")).owner_id;
    }

    console.log("testing error")

    data.master_id = solid_id;

    return axios({
        method: method,
        url: url,
        data: data,
    })
        .then((response) => {
            return { "code": response.status, "data": response.data };
        })
        .catch((error) => {
            return { "code": 0, "data": error.response.data };
        });
};

export const multipartApiCall = (url, method, data) => {
    let solid_id = 0;

    if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))) {
        solid_id = JSON.parse(localStorage.getItem("user")).owner_id;
    }

    data.master_id = solid_id;
    
    return axios({
        method: method,
        url: url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((response) => {
            return { "code": response.status, "data": response.data };
        })
        .catch((error) => {
            return { "code": 0, "data": error.response.data };
        });
};



//login api
export const loginApi = (formData) => apiCall(`${API_URL}user/login`, "POST", formData);

export const verifyaccountApi = (formData) =>  apiCall(`${API_URL}user/verifyaccount?user=${formData.user}`, "POST", formData)

export const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("adminData");
    localStorage.removeItem("canva_Auth");
    localStorage.removeItem("theme");
    localStorage.removeItem("selectedbrand");
    window.location.href = "/";
}

export const register = (username, email, password) => {
    return apiCall(`${API_URL}/register`, "POST", {
        username,
        email,
        password
    }).then((response) => {
        if (response.code === 200) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response;
    });
}

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));;
}

export const isAuthenticated = () => {
    const user = getCurrentUser();
    if (user) {
        return true;
    }
    return false;
}

export const checkAccessLevel = (accessallowedroles) => {
    //accessallowedroles is a array [1,2]
    const user = getCurrentUser();
    if (user) {
        // console.log("accessallowedroles.includes(user.role_id)",accessallowedroles.includes(user.role));
        // console.log("accessallowedroles.includes(user.role_id)",user.role);
        return accessallowedroles.includes(user.role);
    }
    return false;
}


export const demoperiod = ()=>{

    const user = getCurrentUser();
    if (user) {
    const getcreateddate = JSON.parse(localStorage.getItem('user')).joindate;

    //difference of current date and join date

    var date1 = new Date(getcreateddate);
    var date2 = new Date();
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if(Difference_In_Days > 7){
        return false;
    }else{
        return true;
    }
}
    return false;
}











