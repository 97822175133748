import React from "react";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdEdit, MdLink, MdQrCode } from "react-icons/md";
import { Tab, Nav, Row } from "react-bootstrap";
import BrandAssets from "../Components/Editbrand/BrandAssets";
import Socials from "../Components/Editbrand/Socials";
import Qrtemplates from "../Qrtemplates/Qrtemplates";
import MyDomain from "../Components/SettingTabs/MyDomain";
import { FiServer } from "react-icons/fi";
import { Button } from "react-bootstrap";
import { TbMessage2, TbLayoutNavbar, TbListDetails, TbTemplate } from "react-icons/tb";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { addDomainApi, getDomainDataApi } from '../BackendApi/Api/domainApi'
import CreatetemplateBrand from "../Components/Createtemplate/CreatetemplateBrand";
import { RiAddFill } from "react-icons/ri";
import { VanityContext } from '../VanityContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import { getVanityDataApi } from '../BackendApi/Api/vanityApi';
import { getVanityByBrandIdApi, addVanityApi, } from '../BackendApi/Api/vanityApi'
import { BrandContext } from '../brandContext';
import { Accordion } from "react-bootstrap";
import { SiBrandfolder } from "react-icons/si";
import { TbChartBar, TbLayoutGrid, TbEdit, TbTrash } from "react-icons/tb";
import { Link } from "react-router-dom";
import { FlexByBrandId, deleteFlexTemplateApi, deleteVanityApi, updateTeamplateName } from '../BackendApi/Api/brandApi'
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import Loader2 from '../Components/Loader/Loader2';
import { useHistory } from "react-router-dom";
import { useBuilderContext } from "../builderContext";
import { API_URL } from "../BackendApi/Api/config";
import { MdMotionPhotosAuto } from "react-icons/md";
import { getBrandByIdApi, updateBrandApi } from '../BackendApi/Api/brandApi';
import SpinnerLoader from "../Components/SpinnerLoader/SpinnerLoader";
import AddChannel from '../Components/Editbrand/addchannel';
import { AiFillCheckSquare, AiFillClockCircle, AiFillWarning, AiFillX } from "react-icons/ai";







const EditBrand = () => {

    const { setMinipage, setFontFamily, setButtonColor, setColor } = useBuilderContext();

    const history = useHistory();
    console.log('history', history.location)
    const { setBrandId } = useContext(BrandContext);

    const url = window.location.href;
    const urlSplit = url.split('/');
    const urlbrandId = urlSplit[urlSplit.length - 1];
    const [vanity_id, setVanity_id] = useState('');
    const [spinnerLoading, setSpinnerLoading] = useState(false);

    // const { vanityName, setVanityName, vanityLogo, setVanityLogo, vantyCode, setVanityCode, vanitySavedStatus, setVanitySavedStatus, vanityData, setVanityData, getVanityData } = useContext(VanityContext);

    const [vanitySavedStatus, setVanitySavedStatus] = useState(false);
    const [vanityName, setVanityName] = useState('');
    const [vanityLogo, setVanityLogo] = useState('');
    const [vantyCode, setVanityCode] = useState('');
    const [vanityData, setVanityData] = useState([])

    const [loading, setLoading] = useState(true);

    const [brandingID, setBrandingID] = useState('');



    const [company, setCompany] = useState('');

    const [flexData, setFlexData] = useState([]);

    const [templateName, setTemplateName] = useState('');

    const [primary_color, setPrimaryColor] = useState('#000000');
    const [secondary_color, setSecondaryColor] = useState('#B9B3F8');
    const [additional_color_1, setAdditionalColor1] = useState('#000000');
    const [additional_color_2, setAdditionalColor2] = useState('#000000');
    const [logo_light, setLogoLight] = useState('');
    const [logo_dark, setLogoDark] = useState('');
    const [logo_full, setLogoFull] = useState('');
    const [logo_symbolic, setLogoSymbolic] = useState('');
    const [newLoading, setNewLoading] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const [deletedIndex, setDeletedIndex] = useState(0);
    const header = [{
        "type": "HEADER",
        "properties": {
            "title": "Your Business Name",
            "description": "Your Business Description",
            "headerFormat": "H3",
            "profileImage": "1693559177145-5601d1ea-3445-49df-b7cd-95e45e7a49de-istockphoto-1138202866-612x612.jpg",
            "altText": "Profile Image",
            "backgroundImage": "1693559177146-e780f843-6b9c-4285-a1d2-5ff4d575b8c7-pexels-photo-1633578.jpeg"
        },
        showStatus: true
    }]

    const navigate = (direction) => {
        window.history.go(direction);
    }
    const [show02, setShow02] = useState(false);

    const handleClose02 = () => setShow02(false);
    const handleShow02 = () => setShow02(true);
    const [show03, setShow03] = useState(false);

    const handleClose03 = () => setShow03(false);
    const handleShow03 = () => setShow03(true);


    const [show04, setShow04] = useState(false);

    const handleClose04 = () => setShow04(false);
    const handleShow04 = () => setShow04(true);


    const [show05, setShow05] = React.useState(false);

    const handleClose05 = () => setShow05(false);
    const handleShow05 = () => setShow05(true);

    const [show07, setShow07] = useState(false);

    const handleClose07 = () => setShow07(false);
    const handleShow07 = (id, index) => {
        setShow07(true);
        setDeletedIndex(index);
        setDeletedId(id)
    };


    const [show06, setShow06] = useState(false);

    const handleClose06 = () => setShow06(false);
    const handleShow06 = () => setShow06(true);

    const [show09, setShow09] = useState(false);

    const handleClose09 = () => setShow09(false);
    const handleShow09 = () => setShow09(true);

    const [show13, setShow13] = useState(false);

    const handleClose13 = () => setShow13(false);
    const handleShow13 = () => setShow13(true);

    const [newchannel, setnewchannel] = useState(null);

    const [editableTitle, setEditableTitle] = useState(false);
    const [index, setIndex] = useState('');
    const [team_id, setFlex_id] = useState('')

    const [imageUrls, setImageUrls] = useState({
        logo_light: '',
        logo_dark: '',
        logo_full: '',
        logo_symbolic: ''
    });

    const addLogoLight = (e) => {
        setLogoLight(e.target.files[0]);
        const file = e.target.files[0];

        // Check if a file was selected
        if (file) {
            // Convert image to URL
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    logo_light: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };



    const addLogoDark = (e) => {
        setLogoDark(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    logo_dark: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const addLogoFull = (e) => {
        setLogoFull(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    logo_full: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const addLogoSymbolic = (e) => {
        setLogoSymbolic(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageUrls((prevImageUrls) => ({
                    ...prevImageUrls,
                    logo_symbolic: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };
    const [updateTitleLoading, setUpdateTitleLoading] = useState(false)

    const handleUpdateTitle = (e) => {
        setUpdateTitleLoading(true)
        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const data = {
            id: team_id,
            template_name: editableTitle,
            updator_name: user.fname + "" + user.lname,
            updator_image: user.profile_image,
            brand_id: urlbrandId
        }
        updateTeamplateName(data).then((res) => {

            if (res.code === 200) {
                if (res.data.status === true) {
                    setFlexData((flexdata) => {
                        const myData = [...flexdata];
                        myData[index].template_name = editableTitle;
                        return myData;
                    });
                    PNotify.success({
                        title: 'Success',
                        text: 'Updated Successfully',
                    });
                    return true;

                }
                else {
                    PNotify.error({
                        title: "Oh No!",
                        text: res.data.message,
                    });
                }
            }
        }).catch((err) => {
            PNotify.error({
                title: "Oh No!",
                text: err.message,
            })
        }).finally((er) => {
            setUpdateTitleLoading(false)
        })

    }

    const getCompanyBrandAssets = (data) => {
        setCompany(data);
    };

    const addVanityLogo = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setVanityLogo(reader.result);
        };
    };

    //verify domain string
    const verifyDomainString = (domainString) => {
        // verify domain string
        const domainRegex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/);
        return !!domainRegex.test(domainString);

    }


    const addVanity = async (e) => {
        setNewLoading(true)
        if (vanityName === '') {
            PNotify.error({
                title: 'Error',
                text: 'Please enter vanity name',
            });
            setNewLoading(false)
            return false;
        }
        e.preventDefault();
        setLoading(true);

        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const owner_id = user.owner_id;
        const created_by = user._id;


        const formData = {
            vanity_name: vanityName,
            logo: vanityLogo,
            vanity_code: vantyCode,
            owner_id: owner_id,
            created_by: created_by,
            brand_id: urlbrandId,
            updator_name: user.fname + "" + user.lname,
            updator_image: user.profile_image
        }
        addVanityApi(formData).then(response => {
            if (response.code === 200) {
                setVanitySavedStatus(!vanitySavedStatus);
                getVanityData();
                setLoading(false);
                PNotify.success({
                    title: 'Success',
                    text: response.data.message,
                    modules: {
                        Desktop: {
                            desktop: true
                        }
                    }
                });
            }
            else {
                setLoading(false);
                PNotify.error({
                    title: 'Error',
                    text: response.data.message,
                });
            }
        }
        ).catch((err) => {
            PNotify.error({
                title: 'Error',
                text: err.message,
            });
        }).finally((E) => {
            setNewLoading(false)
        })
        handleClose06();

    }

    const [domainName, setDomainName] = useState('');
    const [domainDatastatus, setDomainDataStatus] = useState(false);

    const handleCreateDomain = (e) => {
        e.preventDefault();
        setNewLoading(false)
        //get brand id from url
        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];

        const data = localStorage.getItem("user");
        const user_id = JSON.parse(data)._id;
        const user = JSON.parse(data);
        const domainData = {
            domain_name: domainName,
            owner_id: user_id,
            created_by: user_id,
            brand_id: urlbrandId,
            updator_name: user.fname + "" + user.lname,
            updator_image: user.profile_image
        }

        if (!verifyDomainString(domainName)) {
            PNotify.error({
                title: 'Error',
                text: 'Please enter valid domain name',
            });
            setNewLoading(false)
            return false;
        }
        else {
            setLoading(true);
            setNewLoading(true)
            addDomainApi(domainData).then(response => {
                if (response.code === 200) {
                    setDomainDataStatus(!domainDatastatus);
                    setLoading(false);
                    PNotify.success({
                        title: 'Success',
                        text: 'Domain Added Successfully',
                    });
                    handleClose04();
                }
                else {
                    setLoading(false);
                    PNotify.error({
                        title: 'Error',
                        text: response.message,
                    });
                    handleClose04();
                }
            }
            )
                .catch(error => {
                    setLoading(false);
                    alert(error);
                }).finally((ee) => {
                    setNewLoading(false)
                })
            handleClose04();
        }
    }



    const getVanityData = () => {
        setLoading(true);
        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];
        const brandIdData = {
            brand_id: urlbrandId

        }
        getVanityByBrandIdApi(brandIdData).then((res) => {
            if (res.code == 200) {
                setVanityData(res.data.data)
            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: res.data.message,
                });
            }
        }
        ).catch((error) => {
            alert(error);
        }).finally(() => {
            setLoading(false);
        }
        )
    }

    const getFlexTemplateData = () => {
        setLoading(true);
        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];
        const brandIdData = {
            id: urlbrandId
        }
        FlexByBrandId(brandIdData).then((res) => {
            if (res.code == 200) {
                console.log("flex data============>", res.data.data);
                setFlexData(res.data.data)

            }
        }).catch((error) => {
            alert(error);
        })
    }

    const deletetemplate = (id, ind) => {


        const data = {
            id: deletedId
        }

        deleteFlexTemplateApi(data).then((res) => {
            if (res.code == 200) {
                setFlexData(flexData.filter((item, index) => index !== deletedIndex));
                PNotify.success({
                    title: 'Success',
                    text: 'Template Deleted Successfully',
                });
            }
            else {
                PNotify.error({
                    title: 'Error',
                    text: res.message,
                });
            }
        })



    }

    const saveTemplateTitle = () => {
        setUpdateTitleLoading(true)
        if (templateName == '') {
            PNotify.error({
                title: 'Error',
                text: 'Please enter template name',
            });
            setUpdateTitleLoading(false)
            return false;
        }

        setMinipage(header);
        history.push('/flexpagetemplate/?brandId=' + urlbrandId + '&title=' + templateName);

    }


    const getBrandData = () => {
        const url = window.location.href;
        const urlSplit = url.split('/');
        const urlbrandId = urlSplit[urlSplit.length - 1];

        console.log("brand id ========>", urlbrandId);

        const data = {
            id: urlbrandId
        }

        setLoading(true);

        getBrandByIdApi(data).then((res) => {

            setLoading(false);
            setLogoLight(res.data.data.logo_light);
            setLogoDark(res.data.data.logo_dark);
            setLogoFull(res.data.data.logo_full);
            setLogoSymbolic(res.data.data.logo_symbolic);
            setPrimaryColor(res.data.data.primary_color);
            setSecondaryColor(res.data.data.secondary_color);
            setAdditionalColor1(res.data.data.additional_color_1);
            setAdditionalColor2(res.data.data.additional_color_2);
            setBrandingID(res.data.data._id);
            setImageUrls({
                logo_light: /^(http|https)/.test(res.data.data.logo_light) ? res.data.data.logo_light : API_URL + 'brands/' + res.data.data.logo_light,
                logo_dark: /^(http|https)/.test(res.data.data.logo_dark) ? res.data.data.logo_dark : API_URL + 'brands/' + res.data.data.logo_dark,
                logo_full: /^(http|https)/.test(res.data.data.logo_full) ? res.data.data.logo_full : API_URL + 'brands/' + res.data.data.logo_full,
                logo_symbolic: /^(http|https)/.test(res.data.data.logo_symbolic) ? res.data.data.logo_symbolic : API_URL + 'brands/' + res.data.data.logo_symbolic,
            })
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }

    const updateBrand = async (e) => {
        e.preventDefault();
        setSpinnerLoading(true)
        const formData = new FormData();

        formData.append('logo_light', logo_light);
        formData.append('logo_dark', logo_dark);
        formData.append('logo_full', logo_full);
        formData.append('logo_symbolic', logo_symbolic);
        formData.append('primary_color', primary_color);
        formData.append('secondary_color', secondary_color);
        formData.append('additional_color_1', additional_color_1);
        formData.append('additional_color_2', additional_color_2);
        formData.append('id', brandingID);

        // setLoading(true);
        // setSpinnerLoading(true);
        updateBrandApi(formData).then((response) => {
            if (response.code === 200) {
                // setBrandEditStatus(!brandEditStatus);
                if (response.data.status == true) {
                    setLoading(false);
                    PNotify.success({
                        title: 'Success',
                        text: 'Brand Updated Successfully',
                    });
                } else {
                    setLoading(false);
                    PNotify.error({
                        title: 'Error',
                        text: response.data.message,
                    });
                }
            }
            else {
                setLoading(false);
                PNotify.error({
                    title: 'Error',
                    text: response.data.message,
                });
            }
        }).catch((error) => {
            setLoading(false);
            PNotify.error({
                title: 'Error',
                text: error,
            });
        }).finally(() => {
            setSpinnerLoading(false);
        })
    }

    useEffect(() => {
        getVanityData();
        getFlexTemplateData();
        getBrandData();
    }, [])

    const handleDelete = async (e) => {
        e.preventDefault();

        deleteVanityApi({ id: vanity_id }).then((response) => {
            if (response.code === 200) {
                setLoading(false);
                getVanityData();
                handleClose05();
                PNotify.success({
                    title: "Success",
                    text: "Brand Updated Successfully",
                });
            }
            else {
                setLoading(false);
                handleClose05();
                PNotify.error({
                    title: "Error",
                    text: response.data.message,
                });
            }
        }).catch((error) => {
            setLoading(false);
            PNotify.notice({
                title: "Notice",
                text: error,
            });
        })
    }

    const addnewchanneltolist = (data) => {

        setnewchannel(data)
    }
    const AdminReviewStatuses = {
        PENDING: "Pending",
        REJECTED: "Rejected",
        UNIVERSAL_APPROVAL: "Universal Approval",
        CONDITIONAL_APPROVAL: "Conditional Approval",
    };


    const getStatusContent = (item) => {
        switch (item.AdminReview) {
            case 0:
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4>{`(${AdminReviewStatuses.PENDING})`}</h4>
                        <AiFillClockCircle size={35} style={{ marginLeft: 8 }} />
                    </div>
                );
            case 1:
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4>{`(${AdminReviewStatuses.REJECTED})`}</h4>
                        <AiFillX size={35} style={{ marginLeft: 8, color: 'red' }} />
                    </div>
                );
            case 2:
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4>{`(${AdminReviewStatuses.UNIVERSAL_APPROVAL})`}</h4>
                        <AiFillCheckSquare size={35} style={{ marginLeft: 8, color: 'green' }} />
                    </div>
                );
            case 3:
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4>{`(${AdminReviewStatuses.CONDITIONAL_APPROVAL})`}</h4>
                        <AiFillWarning size={35} style={{ marginLeft: 8, color: 'orange' }} />
                    </div>
                );
            default:
                return null;
        }
    };


    return (
        <>
            {loading && <Loader2 />}

            <Modal show={show07} onHide={handleClose07} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose07}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={() => {
                        handleClose07();
                        deletetemplate();
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* add vanity modal start here */}
            <Modal show={show06} onHide={handleClose06} className="Sidebar-Modal-end createbrand-width">
                <Modal.Header closeButton>
                    <Modal.Title>Add Vanity</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className="row">
                        {/* <div className="col-md-12">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>LOGO/ICON</Form.Label>
                                <Form.Control type="file" placeholder="Company Logo" onChange={addVanityLogo} />
                            </Form.Group>
                        </div> */}
                        <div className="col-md-12">
                            <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                <Form.Control className="did-floating-input" type="text" placeholder="Name" value={vanityName} onChange={(e) => setVanityName(e.target.value)} />
                                <Form.Label className="did-floating-label">NAME</Form.Label>
                            </Form.Group>
                        </div>
                        {/* <div className="col-md-12">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Select Secondary Color</Form.Label>
                                <Form.Control type="color" placeholder="theme colors" defaultValue="#B9B3F8" value={vantyCode} onChange={(e) => { setVanityCode(e.target.value) }} />
                            </Form.Group>
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {newLoading ? <SpinnerLoader /> :
                        <button className="Active-bttn btn" type='submit' onClick={addVanity}> Save </button>}
                    <button className="Cancel-bttn btn" onClick={handleClose06}> Cancel </button>
                </Modal.Footer>
            </Modal>

            {/* new domain modal start here */}
            <Modal show={show04} onHide={handleClose04} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Domain</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Domain Name (Enter domain name without http or https or www)</Form.Label>
                                <Form.Control type="text" placeholder="Domain Name" value={domainName} onChange={(e) => setDomainName(e.target.value)} />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {newLoading ? <SpinnerLoader /> :
                        <button className="Active-bttn btn" onClick={(e) => { handleCreateDomain(e) }}> Create Domain </button>
                    } </Modal.Footer>
            </Modal>
            {/* modal end here */}
            {/* Delete Modal Start From Here */}
            <Modal show={show05} onHide={handleClose05} centered className='Confirm-Delete'>
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><TbTrash /> Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                    <Button className='delete-Cancel-bttn' onClick={handleClose05}>
                        Cancel
                    </Button>
                    <Button className='confirm-Delete-bttn' onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* instrruction modal start here */}
            <Modal show={show03} onHide={handleClose03} className="Sidebar-Modal-end">
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="main-card-title"><TbMessage2 className="header-icon" />Instructions</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="Instructions-content">
                                <p>Add the domain using the new Domain button. After adding the domain, log in to your domain provider such as GoDaddy, Hostinger etc.. Configure the following in your domain provider's DNS settings</p>
                            </div>
                            <div className="Instructions-content">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h5>RECORD TYPE</h5>
                                        <h6>A</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h5>HOSTNAME</h5>
                                        <h6>@</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h5>VALUE</h5>
                                        <h6>{process.env.REACT_APP_SERVER_IP}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="Instructions-content">
                                <p>After configuring above settings, click on verify. If domain is pointing to the above IP address, then it will be marked as verified. <span> Note : It may take few time for the DNS settings to change. Please wait before trying again.</span></p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="Active-bttn btn" onClick={handleClose03}> Confirm </button>
                </Modal.Footer>
            </Modal>
            {/* modal End here */}


            <Modal show={show09} onHide={handleClose09} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Create New FlexTemplate</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicName">
                                <Form.Control className="did-floating-input" type="text" placeholder="Key Name" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
                                <Form.Label className="did-floating-label">Template Title</Form.Label>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {updateTitleLoading ? <SpinnerLoader /> :
                        <button className="Active-bttn btn" onClick={(e) => { saveTemplateTitle() }}> Continue </button>
                    }</Modal.Footer>
            </Modal>

            <Modal show={show02} onHide={handleClose02} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Template Name</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Template Name</Form.Label>
                                <Form.Control type="text" placeholder="Flex Title" value={editableTitle}
                                    onChange={(e) => setEditableTitle(e.target.value)} />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {updateTitleLoading ? <SpinnerLoader /> :
                        <button className="Active-bttn btn" onClick={(e) => { handleClose02(e); handleUpdateTitle() }}> Update Title </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={show13} onHide={handleClose13} centered >
                <AddChannel CloseAddChannelModal={handleClose13} addnewchannel={addnewchanneltolist} />
            </Modal>


            <Header />

            <div className="main-container">
                <div className="main-page-body-content">
                    <div className="main-page-body-content-body">
                        <div className="main-page-body-content-body-container">
                            <div className="tab-content-body p-0">
                                <div className="col-md-12">
                                </div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row className="mobile-view-hide">
                                        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-12">
                                            <div className="Back-btn">
                                                <div className="d-flex align-items-center">
                                                    <button className="Back-btn-icon" onClick={() => history.push("/custombrand")} ><IoArrowBackCircleOutline /></button>
                                                    <div className="Back-btn-text">
                                                        <h5>{company}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-card">
                                                <div className="tab-card-body">
                                                    <div className="tab-content">
                                                        <Nav variant="pills" className="flex-column">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="first" className="technician-tab-link"> <div className="user-profile-box"><TbListDetails /></div> <span className="tab-user-name" >Brand Details</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="seventh" className="technician-tab-link"> <div className="user-profile-box"><MdMotionPhotosAuto /></div> <span className="tab-user-name" >Logos & Visuals</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="second" className="technician-tab-link"> <div className="user-profile-box"><MdLink /></div> <span className="tab-user-name" >Social</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="third" className="technician-tab-link"> <div className="user-profile-box"><MdQrCode /></div> <span className="tab-user-name" >QR Templates</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="sixth" className="technician-tab-link"> <div className="user-profile-box"><TbTemplate /></div> <span className="tab-user-name" id="template" >FlexPage Templates</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="fourth" className="technician-tab-link"> <div className="user-profile-box"><FiServer /></div> <span className="tab-user-name" >Domains</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="fifth" className="technician-tab-link"> <div className="user-profile-box"><TbLayoutNavbar /></div> <span className="tab-user-name" >Vanity URLs</span></Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-10 col-xl-9 col-lg-8 col-md-8 col-12">
                                            <div className="tab-content Smart-Link-Editing bx-1">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <div className="tab-content-header">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><TbListDetails /></div>Brand Details</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <BrandAssets setCompany={setCompany} />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <div className="tab-content-header add-btn d-flex Add-margin p-2">
                                                            <h5 className="tab-content-title ps-2"><div className="tab-content-icon"><MdLink /></div>Social</h5>
                                                            <div className="tab-content-header-right">
                                                                <Button className="btn btn-sm float-right add-usar-btn" onClick={handleShow13} > <FiServer />New Channel</Button>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content-body p-2">
                                                            <Socials newchannel={newchannel} />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="third" className="p-2">
                                                        <div className="">
                                                            <Qrtemplates pageType="template" />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fourth" className="p-2">
                                                        <div className="tab-content-header add-btn d-flex Add-margin ">
                                                            <h5 className="tab-content-title"><div className="tab-content-icon"><FiServer /></div>My Domain</h5>
                                                            <div className="tab-content-header-right">
                                                                <Button className="btn btn-sm float-right add-usar-btn me-3 margin-imp" onClick={handleShow03} > <TbMessage2 />Instructions</Button>
                                                                <Button className="btn btn-sm float-right add-usar-btn" onClick={handleShow04} > <FiServer />New Domain</Button>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <MyDomain setDomainDataStatus={setDomainDataStatus} domainDatastatus={domainDatastatus} />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fifth" className="p-2">
                                                        <div className="tab-content-header add-btn d-flex Add-margin ">
                                                            <h5 className="tab-content-title"><div className="tab-content-icon"><TbLayoutNavbar /></div>Vanity URLs</h5>

                                                            <div className="tab-content-header-right">
                                                                <Button className="btn btn-sm float-right add-user-btn" onClick={() => { handleShow06(); setBrandId(); }} >
                                                                    <RiAddFill /> Add Vanity</Button>
                                                            </div>
                                                        </div>
                                                        <p>Customized back half portion of your short URL to make branding better and verbally sharing your link easier.</p>
                                                        <div className="tab-content-body p-0">
                                                            {vanityData.map((item) => {
                                                                let scans = 320
                                                                let clicks = 10
                                                                let total = scans + clicks

                                                                return (
                                                                    <div className='Vanity-card mb-3'>
                                                                        <div className="Vanity-display-flex mb-2">

                                                                            <div className="Vanity-card-title">
                                                                                <h5>/{item.vanity_name}</h5>
                                                                            </div>
                                                                            <div className="d-flex align-items-center gap-2">
                                                                                <div className="Vanity">
                                                                                    <h5>Status</h5>
                                                                                </div>
                                                                                <div className="Vanity-card-Status">
                                                                                    {getStatusContent(item)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Vanity-display-flex">
                                                                            {/* <div className="Vanity-card-visits">
                                                                                    <h4>0 visits</h4>
                                                                                    <div className="d-flex">
                                                                                        <p className="me-2">0 Clicks</p>
                                                                                        <p>0 Scans</p>
                                                                                    </div>
                                                                                </div> */}

                                                                            <div className="d-flex align-items-center">
                                                                                <div className="d-flex align-items-center justify-content-between text-center">
                                                                                    <h5>{total} <br /><span>Visits</span></h5>
                                                                                    <div style={{ marginLeft: '10px' }}>
                                                                                        <h6>{clicks} Clicks</h6>
                                                                                        <h6 >{scans} Scans</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="button-group">
                                                                                <Link to="/metrics">
                                                                                    <button type="button" className="btn btn-chart me-3">
                                                                                        <TbChartBar />
                                                                                    </button>
                                                                                </Link>
                                                                                {/* <button type="button" className="btn btn-layout me-3" onClick={handleShow05}>
                                                                                        <TbLayoutGrid />
                                                                                    </button>
                                                                                    <Link to="/createflexlink">
                                                                                        <button type="button" className="btn btn-edit me-3">
                                                                                            <TbEdit />
                                                                                        </button>
                                                                                    </Link> */}
                                                                                <button type="button" className="btn btn-delete me-0" onClick={() => {
                                                                                    handleShow05();
                                                                                    setVanity_id(item._id);
                                                                                }} >
                                                                                    <TbTrash />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="sixth" className="p-2">
                                                        <div className="tab-content-header add-btn d-flex Add-margin ">
                                                            <h5 className="tab-content-title"><div className="tab-content-icon"><TbTemplate /></div>FlexPage Templates</h5>
                                                            <div className="tab-content-header-right">
                                                                {/* <Link to={`/flexpagetemplate`}> */}
                                                                <Button
                                                                    className="btn btn-sm float-right add-usar-btn"
                                                                    onClick={() => {
                                                                        handleShow09();
                                                                    }}


                                                                > <FiServer /> Templates</Button>
                                                                {/* </Link> */}
                                                            </div>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <div className="mini-page-list">
                                                                <div className="mini-page-list-inner row">
                                                                    {flexData && flexData.map((item, index) => {
                                                                        return (
                                                                            <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6'>
                                                                                <div className="mini-page-list-inner-item flex-template-list">
                                                                                    <div className="mini-page-list-inner-item-img">
                                                                                        <img src={API_URL + "flextemplates/" + item.template_thumbnail} alt="flexpage" />
                                                                                    </div>
                                                                                    <div className="mini-page-list-inner-item-content">
                                                                                        <div className="mini-page-list-inner-item-content-title">
                                                                                            <h5 className="page-title">
                                                                                                <div className='edit-title' onClick={
                                                                                                    () => {
                                                                                                        setEditableTitle(item.template_name);
                                                                                                        setIndex(index);
                                                                                                        setFlex_id(item._id);
                                                                                                        handleShow02();

                                                                                                    }
                                                                                                }><MdEdit />
                                                                                                </div>{item.template_name}</h5>
                                                                                        </div>
                                                                                        <div className='inline-box'>
                                                                                            <div className='mini-page-list-inner-item-content-btn'>
                                                                                                <div className="d-flex new-M">
                                                                                                    <button type="button" className="btn btn-edit me-3"

                                                                                                        onClick={() => {
                                                                                                            setMinipage(JSON.parse(item.template_json));
                                                                                                            setFontFamily(item.font_family);
                                                                                                            setButtonColor(item.button_color);
                                                                                                            setColor(item.background_color);
                                                                                                            history.push(`/flexpagetemplate/?templateId=${item._id}&brandId=${urlbrandId}`);

                                                                                                        }}

                                                                                                    >
                                                                                                        <TbEdit />
                                                                                                    </button>
                                                                                                    <button type="button" className="btn btn-delete"
                                                                                                        onClick={() => {
                                                                                                            handleShow07(item._id, index);
                                                                                                        }}


                                                                                                    >
                                                                                                        <TbTrash />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )

                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="seventh" className="p-2">
                                                        <div className="tab-content-header add-btn d-flex Add-margin ">
                                                            <h5 className="tab-content-title"><div className="tab-content-icon"><MdMotionPhotosAuto /></div>Logos & Visuals</h5>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <div className='row'>
                                                                <div className='col-md-3'>
                                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                        <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={primary_color} onChange={(e) => { setPrimaryColor(e.target.value) }} />
                                                                        <Form.Label className="did-floating-label">Select Primary Color</Form.Label>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                        <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={secondary_color} onChange={(e) => { setSecondaryColor(e.target.value) }} />
                                                                        <Form.Label className="did-floating-label">Select Secondary Color</Form.Label>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                        <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={additional_color_1} onChange={(e) => { setAdditionalColor1(e.target.value) }} />
                                                                        <Form.Label className="did-floating-label">Additional Color 1</Form.Label>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                        <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={additional_color_2} onChange={(e) => { setAdditionalColor2(e.target.value) }} />
                                                                        <Form.Label className="did-floating-label">Additional Color 2</Form.Label>
                                                                    </Form.Group>
                                                                </div>
                                                                <b className='mb-4'>Update Company Logo</b>

                                                                <div className='col-md-3'>
                                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                        <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={addLogoLight} />
                                                                        <Form.Label className="did-floating-label">Light Background</Form.Label>
                                                                        <div className="bg-light p-2">
                                                                            {logo_light &&
                                                                                <img
                                                                                    src={
                                                                                        imageUrls.logo_light
                                                                                    }
                                                                                    alt="Logo Light Background Preview" className="preview-logo-image" />
                                                                            }
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                        <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={addLogoDark} />
                                                                        <Form.Label className="did-floating-label">Dark Background</Form.Label>
                                                                        <div className="bg-dark p-2">
                                                                            {logo_dark &&
                                                                                <img src={imageUrls.logo_dark} alt="Logo Dark Background Preview" className="preview-logo-image" />
                                                                            }
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                        <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={addLogoFull} />
                                                                        <Form.Label className="did-floating-label">Full Color or Alternate</Form.Label>
                                                                        {logo_full && <img src={imageUrls.logo_full} alt="Logo Full Color Preview" className="preview-logo-image" />}
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                        <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={addLogoSymbolic} />
                                                                        <Form.Label className="did-floating-label">Symbolic</Form.Label>
                                                                        {logo_symbolic && <img src={imageUrls.logo_symbolic} alt="Logo Full Color Preview" className="preview-logo-image" />}
                                                                    </Form.Group>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>
                                                                        <div className='brand-button'>

                                                                            {
                                                                                spinnerLoading ? (
                                                                                    <SpinnerLoader />
                                                                                ) :
                                                                                    (
                                                                                        <button className="btn bg-green-dark action-btn btn-green" type="submit" onClick={updateBrand} > Update </button>
                                                                                    )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </div>
                                        </div>
                                    </Row>
                                </Tab.Container>
                            </div>
                            <div className="row pd-20">
                                <div className="mobile-view-show">
                                    <div className="accordion-list">
                                        <Accordion defaultActiveKey="0">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdLink /></div> <span className="tab-user-name" >Brand Details</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <BrandAssets setCompany={setCompany} />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            {/* <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdMotionPhotosAuto /></div> <span className="tab-user-name" >Logo & Visuals</span></Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="tab-content-body">
                                                                <div className='row'>
                                                                    <div className='col-md-3'>
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={primary_color} onChange={(e) => { setPrimaryColor(e.target.value) }} />
                                                                            <Form.Label className="did-floating-label">Select Primary Color</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={secondary_color} onChange={(e) => { setSecondaryColor(e.target.value) }} />
                                                                            <Form.Label className="did-floating-label">Select Secondary Color</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={additional_color_1} onChange={(e) => { setAdditionalColor1(e.target.value) }} />
                                                                            <Form.Label className="did-floating-label">Additional Color 1</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-color" type="color" placeholder="theme colors" value={additional_color_2} onChange={(e) => { setAdditionalColor2(e.target.value) }} />
                                                                            <Form.Label className="did-floating-label">Additional Color 2</Form.Label>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <b className='mb-4'>Update Company Logo</b>



                                                                    <div className='col-md-3'>
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={addLogoLight} />
                                                                            <Form.Label className="did-floating-label">Light Background</Form.Label>
                                                                            <div className="bg-light p-2">
                                                                            {logo_light && <img src={imageUrls.logo_light.startsWith('http') || imageUrls.logo_light.startsWith('https') ? imageUrls.logo_light : API_URL + `brands/` + imageUrls.logo_light } alt="Logo Light Background Preview" className="preview-logo-image" />}
                                                                            </div>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={addLogoDark} />
                                                                            <Form.Label className="did-floating-label">Dark Background</Form.Label>
                                                                            <div className="bg-dark p-2">
                                                                            {logo_dark && <img src={imageUrls.logo_dark.startsWith('http') || imageUrls.logo_dark.startsWith('https') ? imageUrls.logo_dark : API_URL + `brands/` + imageUrls.logo_dark } alt="Logo Dark Background Preview" className="preview-logo-image" />}

                                                                            </div>
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={addLogoFull} />
                                                                            <Form.Label className="did-floating-label">Full Color or Alternate</Form.Label>
                                                                            {logo_full && <img src={imageUrls.logo_full.startsWith('http') || imageUrls.logo_full.startsWith('https') ? imageUrls.logo_full : API_URL + `brands/` + imageUrls.logo_full } alt="Logo Full Color Preview" className="preview-logo-image" />}
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <Form.Group className="did-floating-label-content" controlId="formBasicEmail">
                                                                            <Form.Control className="did-floating-file" type="file" placeholder="Company Logo" onChange={addLogoSymbolic} />
                                                                            <Form.Label className="did-floating-label">Symbolic</Form.Label>
                                                                            {logo_symbolic && <img src={imageUrls.logo_symbolic.startsWith('http') || imageUrls.logo_symbolic.startsWith('https') ? imageUrls.logo_symbolic : API_URL + `brands/` + imageUrls.logo_symbolic } alt="Logo Full Color Preview" className="preview-logo-image" />}
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </div> */}
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><MdLink /></div> <span className="tab-user-name" >Socials</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body">
                                                            <Socials />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header className="tab-list-header"> <div className="user-profile-box"><MdLink /></div> <span className="tab-user-name" >QR Templates</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body p-0">
                                                            <Qrtemplates pageType="template" />
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header className="tab-list-header"> <div className="user-profile-box"><TbTemplate /></div> <span className="tab-user-name" >FlexPage Templates</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body p-0">
                                                            <div className="tab-content-header add-btn d-flex Add-margin ">
                                                                <h5 className="tab-content-title"><div className="tab-content-icon"><TbTemplate /></div>FlexPage Templates</h5>
                                                                <div className="tab-content-header-right">
                                                                    {/* <Link to={`/flexpagetemplate`}> */}
                                                                    <Button
                                                                        className="btn btn-sm float-right add-usar-btn"
                                                                        onClick={() => {
                                                                            handleShow09();
                                                                        }}


                                                                    > <FiServer />New FlexPage Templates</Button>
                                                                    {/* </Link> */}
                                                                </div>
                                                            </div>
                                                            <div className="tab-content-body p-0">
                                                                <div className="mini-page-list">
                                                                    <div className="mini-page-list-inner row">
                                                                        {flexData && flexData.map((item, index) => {
                                                                            return (
                                                                                <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6'>
                                                                                    <div className="mini-page-list-inner-item flex-template-list">
                                                                                        <div className="mini-page-list-inner-item-img">
                                                                                            <img src={API_URL + "flextemplates/" + item.template_thumbnail} alt="flexpage" />
                                                                                        </div>
                                                                                        <div className="mini-page-list-inner-item-content">
                                                                                            <div className="mini-page-list-inner-item-content-title">
                                                                                                <h5 className="page-title"> <div className='edit-title' onClick={
                                                                                                    () => {
                                                                                                        setEditableTitle(item.template_name);
                                                                                                        setIndex(index);
                                                                                                        setFlex_id(item._id);
                                                                                                        handleShow02();

                                                                                                    }
                                                                                                }><MdEdit />
                                                                                                </div>{item.template_name}</h5>
                                                                                            </div>
                                                                                            <div className='inline-box'>
                                                                                                <div className='mini-page-list-inner-item-content-btn'>
                                                                                                    <div className="d-flex new-M">
                                                                                                        <button type="button" className="btn btn-edit me-3"

                                                                                                            onClick={() => {
                                                                                                                setMinipage(JSON.parse(item.template_json));
                                                                                                                setFontFamily(item.font_family);
                                                                                                                setButtonColor(item.button_color);
                                                                                                                setColor(item.background_color);
                                                                                                                history.push('/flexpagetemplate/?templateId=' + item._id);

                                                                                                            }}

                                                                                                        >
                                                                                                            <TbEdit />
                                                                                                        </button>
                                                                                                        <button type="button" className="btn btn-delete"
                                                                                                            onClick={() => {
                                                                                                                deletetemplate(item._id, index);
                                                                                                            }}


                                                                                                        >
                                                                                                            <TbTrash />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )

                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><TbLayoutNavbar /></div> <span className="tab-user-name" >Vanity URLs</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-header add-btn d-flex Add-margin ">
                                                            <h5 className="tab-content-title"><div className="tab-content-icon"><TbLayoutNavbar /></div>Vanity URLs</h5>
                                                            <div className="tab-content-header-right">
                                                                <Button className="btn btn-sm float-right add-user-btn" onClick={() => { handleShow06(); setBrandId(); }} >
                                                                    <MdLink /> Add Vanity</Button>
                                                            </div>
                                                        </div>
                                                        <div className="tab-content-body p-0">
                                                            <div className='Vanity-card mb-3'>
                                                                <div className="Vanity-display-flex mb-2">
                                                                    <div className="Vanity-card-title">
                                                                        {/* <h4>{item.vanity_name}</h4> */}
                                                                        <p>https://www.facebook.com/</p>
                                                                    </div>
                                                                    <div className="Vanity-card-Status">
                                                                        <h5>Status</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="Vanity-display-flex">

                                                                    <div className="Vanity-card-visits">
                                                                        <h4>100 visits</h4>
                                                                        <div className="d-flex">
                                                                            <p className="me-2">56 Clicks</p>
                                                                            <p>44 Scans</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="button-group">
                                                                        <Link to="/metrics">
                                                                            <button type="button" className="btn btn-chart me-3">
                                                                                <TbChartBar />
                                                                            </button>
                                                                        </Link>
                                                                        <button type="button" className="btn btn-layout me-3" onClick={handleShow05}>
                                                                            <TbLayoutGrid />
                                                                        </button>
                                                                        <Link to="/createflexlink">
                                                                            <button type="button" className="btn btn-edit me-3">
                                                                                <TbEdit />
                                                                            </button>
                                                                        </Link>
                                                                        <button type="button" className="btn btn-delete me-0" onClick={handleShow04}>
                                                                            <TbTrash />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-2">
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header className="tab-list-header"><div className="user-profile-box"><FiServer /></div> <span className="tab-user-name" >Domains</span></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="tab-content-body p-0">
                                                            <div className="tab-content-header add-btn d-flex Add-margin ">
                                                                <h5 className="tab-content-title"><div className="tab-content-icon"><FiServer /></div>My Domain</h5>
                                                                <div className="tab-content-header-right">
                                                                    <Button className="btn btn-sm float-right add-usar-btn me-3 margin-imp" onClick={handleShow03} > <TbMessage2 />Instructions</Button>
                                                                    <Button className="btn btn-sm float-right add-usar-btn" onClick={handleShow04} > <FiServer />New Domain</Button>
                                                                </div>
                                                            </div>
                                                            <div className="tab-content-body p-0">
                                                                <MyDomain domainDatastatus={domainDatastatus} />
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditBrand;