import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import axios from "axios";
import { MdAdd, MdOutlineMoreHoriz, MdOutlineModeEdit, MdDeleteOutline } from "react-icons/md";
import { MdSchedule, MdDelete, MdMoreVert } from "react-icons/md";
import { PiDotsSixBold } from "react-icons/pi";
import { TbTrash, TbEdit } from "react-icons/tb";
import { Button, Modal, Dropdown, Form } from "react-bootstrap";
import CreatePostIdea from "./CreatePostIdea";
import EditPostIdea from "./EditPostIdea";
import CreateGroup from "./CreateColumn";
import { FaCalendarAlt } from "react-icons/fa";
import { FiVideo } from "react-icons/fi";
import { FaComment } from "react-icons/fa6";
import BulkSchedule from "../bulkschedule";
import Comments from './Comments';
import { useLocation } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;

const KanbanBoard = () => {
    const [columns, setColumns] = useState([]);
    const [show, setShow] = useState(false);
    const [show02, setShow02] = useState(false);
    const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
    const [showDeleteIdeaModal, setShowDeleteIdeaModal] = useState(false);
    const [showCommentIdeaModal, setShowCommentIdeaModal] = useState(false);
    const [showEditIdeaModal, setShowEditIdeaModal] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [selectedIdea, setSelectedIdea] = useState(null);
    const [targetColumnId, setTargetColumnId] = useState('');
    const [ideacPost_id, setideacPost_id] = useState(null);

    const [editIdeaData, setEditIdeaData] = useState(null);

    const [showRenameModal, setShowRenameModal] = useState(false);
    const [newColumnName, setNewColumnName] = useState('');

    const [scheduleData, setScheduleData] = React.useState({});


    const didMount = useRef(false);

    useEffect(() => {
        if (!didMount.current) {
            const fetchData = async () => {
                try {
                    const user = JSON.parse(localStorage.getItem("user"));
                    const response = await axios.post(
                        `${API_URL}/socialposts/getKanbanColumns`,
                        { owner_id: user._id }
                    );
                    setColumns(response.data.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
            fetchData();
            didMount.current = true;
        }
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose02 = () => setShow02(false);
    const handleShow02 = () => setShow02(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleconfirmClose03 = () => {
        const shouldClose = window.confirm('Are you sure want to quit all your changes will be lost ?');
        if (shouldClose) {
            setShow03(false);
        }
    }
    const [show03, setShow03] = useState(false);
    const handleClose03 = () => setShow03(false);
    const handleShow03 = () => setShow03(true);

    const handleCloseEditIdea = () => setShowEditIdeaModal(false);
    const handleShowEditIdea = () => setShowEditIdeaModal(true);
    const handlecommentClose06 = () => { setShowCommentIdeaModal(false) }
    const handleCommentShow06 = (item) => {
        setideacPost_id(item?._id)
        setShowCommentIdeaModal(true)

    }


    const handleShowRenameModal = (column) => {
        setSelectedColumn(column);
        setNewColumnName(column.column_title);
        setShowRenameModal(true);
    };

    const handleCloseRenameModal = () => {
        setShowRenameModal(false);
        setSelectedColumn(null);
        setNewColumnName('');
    };

    const handleRenameColumn = async () => {
        try {
            const response = await axios.post(`${API_URL}/socialposts/renameColumn`, {
                columnId: selectedColumn._id,
                newTitle: newColumnName
            });

            if (response.data.success) {
                setColumns(prevColumns =>
                    prevColumns.map(col =>
                        col._id === selectedColumn._id
                            ? { ...col, column_title: newColumnName }
                            : col
                    )
                );
                handleCloseRenameModal();
            }
        } catch (error) {
            console.error("Error renaming column:", error);
        }
    };

    const onDragEnd = async (result) => {
        const { source, destination, type } = result;

        if (!destination) return;

        if (type === 'COLUMN') {
            const newColumnOrder = Array.from(columns);
            const [reorderedColumn] = newColumnOrder.splice(source.index, 1);
            newColumnOrder.splice(destination.index, 0, reorderedColumn);
            setColumns(newColumnOrder);

            await updateColumnOrder(newColumnOrder);
            return;
        }

        const sourceColumn = columns.find(col => col._id === source.droppableId);
        const destColumn = columns.find(col => col._id === destination.droppableId);

        if (sourceColumn === destColumn) {
            const newItems = Array.from(sourceColumn.postIdeas);
            const [reorderedItem] = newItems.splice(source.index, 1);
            newItems.splice(destination.index, 0, reorderedItem);

            const newColumn = {
                ...sourceColumn,
                postIdeas: newItems,
            };

            setColumns(columns.map(col => col._id === newColumn._id ? newColumn : col));

            await updateItemOrder(newColumn._id, newItems);
        } else {

            const sourceItems = Array.from(sourceColumn.postIdeas);
            const destItems = Array.from(destColumn.postIdeas);
            const [movedItem] = sourceItems.splice(source.index, 1);

            // Update the column_id of the moved item
            const updatedMovedItem = {
                ...movedItem,
                column_id: destColumn._id
            };

            destItems.splice(destination.index, 0, updatedMovedItem);

            const newSourceColumn = {
                ...sourceColumn,
                postIdeas: sourceItems,
            };

            const newDestColumn = {
                ...destColumn,
                postIdeas: destItems,
            };

            setColumns(columns.map(col => {
                if (col._id === newSourceColumn._id) return newSourceColumn;
                if (col._id === newDestColumn._id) return newDestColumn;
                return col;
            }));

            // Pass the updated item to the updateItemMovement function
            await updateItemMovement(updatedMovedItem._id, newDestColumn._id, destItems);





        }
    };

    const updateColumnOrder = async (newOrder) => {
        try {
            await axios.post(`${API_URL}/socialposts/updateColumnOrder`, { newOrder });
        } catch (error) {
            console.error("Error updating column order:", error);
        }
    };

    const updateItemOrder = async (columnId, items) => {
        try {
            await axios.post(`${API_URL}/socialposts/updateItemOrder`, { columnId, items });
        } catch (error) {
            console.error("Error updating item order:", error);
        }
    };

    const updateItemMovement = async (itemId, newColumnId, newColumnItems) => {
        try {
            await axios.post(`${API_URL}/socialposts/updateItemMovement`, { itemId, newColumnId, newColumnItems });
        } catch (error) {
            console.error("Error updating item movement:", error);
        }
    };



    const addBackIdea = (newIdea) => {
        setColumns(prevColumns => {
            const updatedColumns = prevColumns.map(column => {
                if (column._id === newIdea.column_id) {
                    return {
                        ...column,
                        postIdeas: [...column.postIdeas, newIdea]
                    };
                }
                return column;
            });
            return updatedColumns;
        });
    };

    const updateBackIdea = (sourceColumnId, targetColumnId, updatedIdea) => {
        setColumns(prevColumns => {
            return prevColumns.map(column => {
                // If this is the source column and it's different from the target
                if (column._id === sourceColumnId && sourceColumnId !== targetColumnId) {
                    return {
                        ...column,
                        postIdeas: column.postIdeas.filter(idea => idea._id !== updatedIdea._id)
                    };
                }
                // If this is the target column
                else if (column._id === targetColumnId) {
                    if (sourceColumnId === targetColumnId) {
                        // Update the idea in the same column
                        return {
                            ...column,
                            postIdeas: column.postIdeas.map(idea =>
                                idea._id === updatedIdea._id ? updatedIdea : idea
                            )
                        };
                    } else {
                        // Add the idea to the new column
                        return {
                            ...column,
                            postIdeas: [...column.postIdeas, updatedIdea]
                        };
                    }
                }
                // Return the column unchanged if it's not involved
                return column;
            });
        });
    };

    const addBackGroup = (newGroup) => {
        setColumns(prevColumns => [...prevColumns, newGroup]);
    };

    const handleShowDeleteGroupModal = (column) => {
        setSelectedColumn(column);
        setShowDeleteGroupModal(true);
    };

    const handleCloseDeleteGroupModal = () => {
        setShowDeleteGroupModal(false);
        setSelectedColumn(null);
        setTargetColumnId('');
    };

    const handleShowDeleteIdeaModal = (idea) => {
        setSelectedIdea(idea);
        setShowDeleteIdeaModal(true);
    };

    const handleCloseDeleteIdeaModal = () => {
        setShowDeleteIdeaModal(false);
        setSelectedIdea(null);
    };

    const handleMoveAndDeleteGroup = async () => {
        try {
            // Perform the server-side operation
            await axios.post(`${API_URL}/socialposts/moveAndDeleteGroup`, {
                sourceColumnId: selectedColumn,
                targetColumnId: targetColumnId
            });

            // Update local state
            setColumns(prevColumns => {
                // Find the source and target columns
                const sourceColumn = prevColumns.find(col => col._id === selectedColumn);
                const targetColumn = prevColumns.find(col => col._id === targetColumnId);

                if (!sourceColumn || !targetColumn) {
                    console.error("Source or target column not found");
                    return prevColumns;
                }

                // Move ideas from source to target
                const updatedTargetColumn = {
                    ...targetColumn,
                    postIdeas: [...targetColumn.postIdeas, ...sourceColumn.postIdeas]
                };

                // Remove the source column and update the target column
                return prevColumns
                    .filter(col => col._id !== selectedColumn)
                    .map(col => col._id === targetColumnId ? updatedTargetColumn : col);
            });

            handleCloseDeleteGroupModal();
        } catch (error) {
            console.error("Error moving ideas and deleting group:", error);
        }
    };
    const handleDeleteGroupAndIdeas = async () => {
        try {
            await axios.post(`${API_URL}/socialposts/deleteGroupAndIdeas`, {
                columnId: selectedColumn,
            });

            // Update local state
            setColumns(prevColumns => prevColumns.filter(col => col._id !== selectedColumn));

            handleCloseDeleteGroupModal();
        } catch (error) {
            console.error("Error deleting group and ideas:", error);
        }
    };

    const handleDeleteIdea = async () => {
        try {
            await axios.post(`${API_URL}/socialposts/deleteIdea`, {
                ideaId: selectedIdea._id,
            });

            // Update local state
            setColumns(prevColumns => {
                return prevColumns.map(column => {
                    if (column._id === selectedIdea.column_id) {
                        return {
                            ...column,
                            postIdeas: column.postIdeas.filter(idea => idea._id !== selectedIdea._id)
                        };
                    }
                    return column;
                });
            });

            handleCloseDeleteIdeaModal();
        } catch (error) {
            console.error("Error deleting idea:", error);
        }
    };

    const [deleteModal, setDeleteModal] = useState(false);


    const handleDeleteModal = () => {
        if (deleteModal) {
            setDeleteModal(false);
        } else {
            setDeleteModal(true);
        }
    };

    const handleCloseDeleteIdea = () => {
        setDeleteModal(false);
    }

    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };

    const generateThumbnail = (url) => {

    }
    const localData = JSON.parse(localStorage.getItem("design_data"));
    const location = useLocation();

useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const source = urlParams.get('source');
    
    console.log("This is the source", source);
    
    if (source === 'canva_redirect') {
        const localData = JSON.parse(localStorage.getItem("design_data"));

        if (localData) {
            if (localData.method === "edit_idea") {
                console.log("LocalData (edit_idea)", localData);
                console.log("Columns", columns);

                // Ensure columns array exists and has data
                if (Array.isArray(columns) && columns.length > 0) {
                    const post = columns.find(column => column._id === localData.selectedColumnId);
                    console.log("Selected Post", post);

                    if (post) {
                        handleShow();
                    } else {
                        console.log("No post found with the given ID.");
                    }
                } else {
                    console.log("Columns is not a valid array or is empty.");
                }

            } else if (localData.method === "add_idea") {
                console.log("LocalData (add_idea)", localData);
                handleShow();
            }
        }
    }

}, [columns, location.search]);
    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                centered
                size="lg"
                className="Sidebar-Modal-end"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title">
                        <MdSchedule />
                        New Idea
                    </Modal.Title>
                </Modal.Header>
                <CreatePostIdea
                    closemodal={handleClose}
                    addpostwa={addBackIdea}
                    columns={columns}
                    selectedColumnId={localData ? localData.selectedColumnId : selectedColumn}
                />
            </Modal>

            <Modal
                show={show02}
                onHide={handleClose02}
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title">
                        <MdSchedule />
                        Group
                    </Modal.Title>
                </Modal.Header>
                <CreateGroup
                    closemodal={handleClose02}
                    addgroupwa={addBackGroup}
                    columns={columns}
                />
            </Modal>

            <Modal show={showDeleteGroupModal} onHide={handleCloseDeleteGroupModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Select a group to move ideas after deleting group</Form.Label>
                        <Form.Control
                            as="select"
                            value={targetColumnId}
                            onChange={(e) => setTargetColumnId(e.target.value)}
                        >
                            <option value="">Select a group</option>
                            {columns.filter(col => col._id !== selectedColumn).map((col) => (
                                <option key={col._id} value={col._id}>
                                    {col.column_title}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleMoveAndDeleteGroup} disabled={!targetColumnId}>
                        Move and Delete
                    </Button>
                    <Button variant="danger" onClick={handleDeleteGroupAndIdeas}>
                        Delete Group and Ideas
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* New Delete Idea Modal */}
            <Modal show={showDeleteIdeaModal} onHide={handleCloseDeleteIdeaModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Idea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Do you want to delete this idea?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteIdeaModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteIdea}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showEditIdeaModal}
                onHide={handleCloseEditIdea}
                centered
                size="lg"
                className="Sidebar-Modal-end"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Idea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditPostIdea
                        columns={columns}
                        closemodal={handleCloseDeleteIdeaModal}
                        editIdeaData={editIdeaData}
                        addpostwa={addBackIdea}
                        openmodel={handleShow}
                        updatepostwa={updateBackIdea}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={showRenameModal} onHide={handleCloseRenameModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Rename Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>New Group Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={newColumnName}
                            onChange={(e) => setNewColumnName(e.target.value)}
                            placeholder="Enter new group name"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseRenameModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleRenameColumn}>
                        Rename
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show03} onHide={handleconfirmClose03} centered size="lg" className='Sidebar-Modal-end' backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Create Post</Modal.Title>
                </Modal.Header>
                <BulkSchedule closemodal={handleClose03} method="all" scheduleBinData={scheduleData} />
            </Modal>


            {/* comments modal */}

            <Modal show={showCommentIdeaModal} onHide={handlecommentClose06} centered size="lg" className='Sidebar-Modal-end' backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title className="delete-modal-title"><MdSchedule />Comments</Modal.Title>
                </Modal.Header>
                <Comments closemodal={handlecommentClose06} post_id={ideacPost_id} />
            </Modal>
            <div className="col-md-12">
                <div className="add-btn d-flex Add-margin">
                    <h5 className="tab-content-title">
                        <div className="tab-content-icon">
                            <MdSchedule />
                        </div>
                        Post Ideas
                    </h5>
                    <div className="tab-content-header-right d-flex">
                        <Button
                            className="btn create_btn me-2"
                            onClick={handleShow02}
                        >
                            <MdAdd />
                            New Group
                        </Button>
                        <Button
                            className="btn create_btn"
                            onClick={handleShow}
                        >
                            <MdAdd />
                            New Idea
                        </Button>
                    </div>
                </div>
            </div>

            <div className={'publish_overlay_xuh23'} style={{ display: deleteModal ? 'flex' : 'none' }}>
                <div className='delete_modal'>
                    <div className='delete_modal_content'>
                        <div className='delete_modal_header'>
                            <h3>Delete Idea</h3>
                        </div>
                        <p>This idea will be permanently deleted. This action cannot be undone.</p>
                    </div>
                    <div className='delete_modal_actions'>
                        <button className='cancel_btn' onClick={handleCloseDeleteIdea}>Cancel</button>
                        <button className='delete_btn_red' onClick={handleDeleteIdea}>Delete</button>
                    </div>
                </div>
            </div>


            <div className='dnd_board' style={{ width: '100%', overflowX: 'auto' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="board" type="COLUMN" direction="horizontal">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    display: 'flex',
                                    minWidth: 'max-content',
                                    padding: '8px 0'
                                }}
                            >
                                {columns && columns.map((column, index) => (
                                    <>
                                        <Draggable key={column._id} draggableId={column._id} index={index}>
                                            {(provided) => (
                                                <div
                                                    className='dnd_column'
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        padding: '8px',
                                                        backgroundColor: '#f4f5fa',
                                                        margin: '8px',
                                                        border: '1px solid lightgrey',
                                                        borderRadius: '2px',
                                                        width: '300px',
                                                        minWidth: '300px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        height: '80vh',
                                                        overflowY: 'auto',
                                                        ...provided.draggableProps.style,
                                                    }}
                                                >
                                                    <div className='dnd_top_icon' {...provided.dragHandleProps}>
                                                        <PiDotsSixBold />
                                                    </div>
                                                    <div
                                                        className='dnd_header'
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            padding: '8px',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <div className='dnd_header_title d-flex align-items-center gap-3'>
                                                            <h3 style={{ margin: 0 }}>{column.column_title}</h3>
                                                            <span>{(column.postIdeas && column.postIdeas.length > 0) ? column.postIdeas.length : 0} </span>
                                                        </div>

                                                        <div className='dnd_header_actions'>
                                                            <button className='add-btn-post' onClick={() => {

                                                                setSelectedColumn(column._id)
                                                                handleShow()

                                                            }}><MdAdd /></button>
                                                            <div className="dropdown-dnd" key={column._id}>
                                                                <button
                                                                    className='add-btn-post btn_dropdown'
                                                                    onClick={() => toggleDropdown(index)}
                                                                >
                                                                    <MdOutlineMoreHoriz />
                                                                </button>
                                                                {openDropdownIndex === index && (
                                                                    <div className='dropdown-menu-dnd'>
                                                                        <ul>
                                                                            <li>
                                                                                <button
                                                                                    className='dropdown-item'
                                                                                    onClick={() => {
                                                                                        handleShowRenameModal(column);
                                                                                        setOpenDropdownIndex(null);
                                                                                    }}
                                                                                >
                                                                                    <MdOutlineModeEdit /> Rename Group
                                                                                </button>
                                                                            </li>
                                                                            <li>
                                                                                <button
                                                                                    className='dropdown-item'
                                                                                    onClick={() => {
                                                                                        handleShowDeleteGroupModal(column._id);
                                                                                        setOpenDropdownIndex(null);
                                                                                    }}
                                                                                >
                                                                                    <MdDeleteOutline />Delete Group
                                                                                </button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Droppable droppableId={column._id} type="ITEM">
                                                        {(provided) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                                style={{ flexGrow: 1, minHeight: '100px' }}
                                                            >
                                                                {(column.postIdeas && column.postIdeas.length > 0) && column.postIdeas.map((item, index) => (
                                                                    <Draggable key={item._id} draggableId={item._id} index={index}>
                                                                        {(provided) => (
                                                                            <>
                                                                                <div
                                                                                    className='dnd_post'
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    ref={provided.innerRef}
                                                                                    style={{
                                                                                        userSelect: 'none',
                                                                                        margin: '0 0 8px 0',
                                                                                        minHeight: '50px',
                                                                                        backgroundColor: 'white',
                                                                                        borderRadius: '2px',
                                                                                        border: '1px solid lightgrey',
                                                                                        zIndex: 1,
                                                                                        ...provided.draggableProps.style,
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className='dnd_post_content'
                                                                                        onClick={() => {
                                                                                            setEditIdeaData(item);
                                                                                            handleShowEditIdea();
                                                                                        }}
                                                                                    >


                                                                                        <div className='dnd_post_image_grid' data-media-count={item.mediaType === "image" ? item.images.length : item.videos.length}>
                                                                                            {item.mediaType === "image" ? (
                                                                                                <>
                                                                                                    {item.images.slice(0, 4).map((image, index) => (
                                                                                                        <div className='dnd_image-grid-item' key={index}>
                                                                                                            <img src={image.imgUrl.startsWith('https') ? image.imgUrl : `${API_URL}/posts/${image.imgUrl}`} alt="Post Image" />
                                                                                                        </div>
                                                                                                    ))}
                                                                                                    {item.images.length > 4 && (
                                                                                                        <div className='dnd_more-media'>
                                                                                                            <span>+{item.images.length - 4}</span>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {item.videos.slice(0, 4).map((video, index) => (
                                                                                                        <div className='dnd_video-grid-item' key={index}>

                                                                                                            <video
                                                                                                                src={video.imgUrl.startsWith('https') ? video.imgUrl : `${API_URL}/posts/${video.imgUrl}`}
                                                                                                                preload="metadata"
                                                                                                                muted
                                                                                                                playsInline
                                                                                                            >
                                                                                                                Your browser does not support the video tag.
                                                                                                            </video>
                                                                                                            <div className='dnd_video-icon-overlay'>
                                                                                                                <FiVideo />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                    {item.videos.length > 4 && (
                                                                                                        <div className='dnd_video-grid-item dnd_more-media'>
                                                                                                            <span>+{item.videos.length - 4}</span>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className='dnd_post_details'>
                                                                                            <div className='dnd_post_title'>
                                                                                                <h4>{item.title}</h4>
                                                                                            </div>
                                                                                            <p className='dnd_post_caption'>{item.caption}</p>
                                                                                        </div>
                                                                                        <div
                                                                                            className="d-flex new-M justify-content-between w-100 new-M"
                                                                                        >
                                                                                            <button
                                                                                                aria-label="Calendar"
                                                                                                tooltip-position="top"
                                                                                                type='button'
                                                                                                className="btn btn-action btn-calendar btn-square flex-grow-1"
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation(); // Prevent event bubbling

                                                                                                    setScheduleData(item)
                                                                                                    handleShow03()
                                                                                                }

                                                                                                }
                                                                                            >
                                                                                                <FaCalendarAlt />
                                                                                            </button>
                                                                                            <button
                                                                                                aria-label="Edit"
                                                                                                tooltip-position="top"
                                                                                                type="button"
                                                                                                className="btn btn-action btn-edit btn-square flex-grow-1"
                                                                                            >
                                                                                                <TbEdit />
                                                                                            </button>
                                                                                            <button
                                                                                                aria-label='Delete'
                                                                                                tooltip-position="top"
                                                                                                type="button"
                                                                                                className="btn btn-action btn-delete btn-square flex-grow-1"
                                                                                                onClick={
                                                                                                    (e) => {
                                                                                                        e.stopPropagation(); // Prevent event bubbling

                                                                                                        handleShowDeleteIdeaModal(item)
                                                                                                    }
                                                                                                }
                                                                                            >
                                                                                                <TbTrash />
                                                                                            </button>
                                                                                            <button
                                                                                                aria-label='Comments'
                                                                                                tooltip-position="top"
                                                                                                type="button"
                                                                                                className="btn btn-action btn-comment btn-square flex-grow-1"
                                                                                                onClick={
                                                                                                    (e) => {
                                                                                                        e.stopPropagation(); // Prevent event bubbling

                                                                                                        handleCommentShow06(item)
                                                                                                    }
                                                                                                }
                                                                                            >
                                                                                                <FaComment />
                                                                                            </button>
                                                                                        </div>

                                                                                    </div>


                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}

                                                                <button
                                                                    className="add-new-idea-btn"
                                                                    onClick={() => {
                                                                        setSelectedColumn(column._id)
                                                                        handleShow()
                                                                    }}
                                                                >
                                                                    + New Idea
                                                                </button>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            )}
                                        </Draggable>
                                    </>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

        </>
    )
};

export default KanbanBoard;