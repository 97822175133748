import React, { useContext, useState } from 'react';
import { UserContext } from '../UserContext';
import { Button, Image, Alert, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included
import { AiOutlineDisconnect } from 'react-icons/ai';
import { logout } from '../BackendApi/Api/auth';
const UnableUser = () => {
    const { userLoginDisabled, setUserLoginDisabled } = useContext(UserContext);

    console.log("userLoginDisabled", userLoginDisabled);

    // Sample status messages
    const accountStatus = {
        suspended: "Your account was suspended by an Admin. Please speak to support to resolve.",
        notPaid: "Please pay to bring your account status current. [click here]",
        trialEnding: "Your Trial is ending in X Days.",
        trialEnded: "Your Trial has ended, you’ve been downgraded to Free Tier. If you wish to upgrade [click here]",
    };

    // Example of setting the notification type
    const notificationType = 'notPaid'; // Change this to test different notifications
const logOutHandle= async()=>{
        localStorage.removeItem("user");
        window.location.href = "/";
}
    return (
        <>
           <div className="card w-100">
                <div className="d-flex justify-content-center align-items-center gap-3 bg-danger">
                    <span className="plan-title">
                        <AiOutlineDisconnect />You are disable by admin !
                    </span>
                </div>
            </div >
            {/* Notification Bar */}
            {/* {notificationType === 'suspended' && (
                <Alert variant="danger" className="text-center mb-4">
                    {accountStatus.suspended}
                </Alert>
            )}
            {notificationType === 'notPaid' && (
                <Alert variant="danger" className="text-center mb-4">
                    {accountStatus.notPaid}
                </Alert>
            )}
            {notificationType === 'trialEnding' && (
                <Alert variant="warning" className="text-center mb-4">
                    {accountStatus.trialEnding}
                </Alert>
            )}
            {notificationType === 'trialEnded' && (
                <Alert variant="warning" className="text-center mb-4">
                    {accountStatus.trialEnded}
                </Alert>
            )} */}

<div style={{ backgroundColor: '#f0f4f8', minHeight: '100vh', padding: '50px 0' }}>
            <Row className="justify-content-center mt-5">
                <Col md={8} className="text-center" style={{ backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)', padding: '40px' }}>
                    <img
                        src="https://p1.hiclipart.com/preview/344/286/350/icon-login-password-user-lock-and-key-share-icon-security-blue-padlock-png-clipart.jpg" // Replace with your image URL
                        alt="User Lock Icon"
                        style={{
                            width: '200px',
                            height: '200px',
                            marginBottom: '30px',
                            borderRadius: "50%", // Use 50% for a circular image
                            border: '3px solid #007bff' // Optional: add a border for visual appeal
                        }}
                    />
                    <h2 className="mt-4" style={{ color: '#343a40' }}>User Disabled</h2>
                    <p className="mb-4" style={{ color: '#6c757d' }}>
                        Your account has been disabled by an Admin. This may be due to a violation of our policies or for other administrative reasons.
                    </p>
                    <p style={{ color: '#6c757d' }}>
                        To regain access, please reach out to our support team. We will assist you in resolving this matter promptly.
                    </p>
                    <p className="mb-4" style={{ color: '#6c757d' }}>
                        If you believe this action was taken in error, kindly provide us with any relevant information.
                    </p>

                    {/* Optional: Contact Support Link */}
                    <a href="mailto:support@example.com" style={{ textDecoration: 'underline', color: '#007bff' }}>
                        Contact Support
                    </a>
                    <br />
                    <br />
                    
                    <Button
                        variant="danger"
                        onClick={logout}
                        style={{ padding: '10px 20px', fontSize: '16px' }} // Add padding and font size for better visibility
                    >
                        Logout
                    </Button>
                </Col>
            </Row>
        </div>
        </>
    );
}

export default UnableUser;
