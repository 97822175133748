import React, { useState, useRef, useEffect, useContext } from "react";

import { FaCircleCheck } from "react-icons/fa6";
import { FaAngleDown, FaAngleUp, FaFacebook, FaLinkedin } from "react-icons/fa6";
import { Button } from "react-bootstrap";
import { socialAccountContext } from "../../contexts/context/socialAccountContext";
import "firebase/auth";
import { app } from "./../firebase.config";
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";

import axios from "axios";
import SpinnerLoader from "../../Components/SpinnerLoader/SpinnerLoader";
import useSocialChannels from "../../contexts/actions/useSocialChannels";
import useSocialChannelsDisconnect from "../../contexts/actions/useSocialChannelsDisconnect";

const API_URL = process.env.REACT_APP_API_URL;

const Channels = () => {

    const { connectedAccount, connectedAccountdispatch } = useContext(socialAccountContext);
    const { getConnectedChannels } = useSocialChannels();
    const { disConnectedChannels } = useSocialChannelsDisconnect()
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (connectedAccount.accounts) {
            const facebookChannel = connectedAccount.accounts.filter(
                (channel) => channel.platform === "LINKEDIN" && channel.LinkedIn_connected == true
            );
            console.log("facebookChannel", facebookChannel)
            setChannels(facebookChannel);
        }
    }, [connectedAccount]);


    const user = JSON.parse(localStorage.getItem('user'));




    const handlePostMessage = (event) => {
        if (event.data.type === "linkedin_auth") {
            const { accessToken } = event.data.message;

            if (accessToken) {
                const userData = event.data.message; // Assuming this contains the complete user data
                getConnectedChannels()
                try {
                    // Store user data in localStorage
                    //   localStorage.setItem("linkedUser", JSON.stringify(userData));
                    // console.log("User data stored in localStorage:", userData);
                } catch (error) {
                    console.error("Error storing user data in localStorage:", error);
                }
            }
        }
    };



    const [isOpen, setIsOpen] = useState(false);

    const decoratedOnClick = () => {
        setIsOpen(!isOpen);
    };
    const CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
    const CLIENT_SECRET = process.env.REACT_APP_LINKEDIN_CLIENT_SECRET;
    const REDIRECT_URI = `${process.env.REACT_APP_MODE == "dev" ? 'http://127.0.0.1:5000' : "https://xrl.ink"}/linkedin/auth/callback`

    const scope = encodeURIComponent("r_1st_connections_size r_organization_admin w_organization_social r_basicprofile rw_ads email profile openid w_member_social r_ads r_ads_reporting r_organization_social rw_organization_admin");
    const owner_id = user.owner_id

    const handleLinkedInLogin = () => {
        setLoading(true)
        const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${scope}&state=${owner_id}`;

        const width = 600;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        const popupWindow = window.open(
            oauthUrl,
            "LinkedIn Login",
            `width=${width},height=${height},left=${left},top=${top}`,
        );
        // Add message event listener to handle postMessage from popup window
        window.addEventListener("message", handlePostMessage);
        console.log("message")
        // Close the popup window when the main window is closed
        window.onbeforeunload = () => {
            popupWindow.close();
            setLoading(false)
            window.removeEventListener("message", handlePostMessage);
        };
        const checkPopupClosed = setInterval(() => {
            if (popupWindow.closed) {
                clearInterval(checkPopupClosed);
                setLoading(false)
                // Handle popup window closed
                // Optionally, you can trigger an action here (e.g., check for authorization code)
            }
        }, 1000);

    };
    const handleDisconnect = async (social_ids) => {
        try {
            console.log("social_ids",social_ids)
            await disConnectedChannels("LinkedIn", social_ids);
            getConnectedChannels(); // Refresh the connected channels
        } catch (error) {
            console.error("Error disconnecting:", error);
        }
    };

    return (

        <>

            <div className="channel-list">
                <div className="channel-box-wrapper">
                    <div className={`fQzPXW accordion-header ${isOpen ? 'active' : ''}`}>
                        <div className="channel-list-item">
                            <div className="channel-left-side">
                                <div className="social-icon">
                                    <img src={`${API_URL}/channels/1690434819690-linkedin.png`} alt="facebook" />
                                </div>
                                <div className="social-details">
                                    <div className="social-name d-flex">
                                        <h4 className="m-0">LinkedIn</h4>
                                    </div>
                                    {/* <div className="social-description">
                                        Page or Group
                                    </div> */}
                                </div>
                            </div>
                            <div className="channel-right-side">
                                {channels.length > 0 ? (
                                    <Button onClick={() => handleDisconnect(channels[0]._id)} className="btn btn-sm float-right connect-user-btn">Disconnect</Button>
                                ) : (
                                    <Button onClick={handleLinkedInLogin} className="btn btn-sm float-right connect-user-btn">Connect</Button>
                                )} 
                                <div className="channel-right-side-btn bPqQjn">
                                    <Button onClick={decoratedOnClick} className="btn transparent-btn">
                                        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isOpen && (
                        <div className="accordion-collapse">
                            <div className="accordion-body-content">
                                <div className="connected-channels">
                                    <h3 className="accordion-title">Connected channels</h3>
                                    {channels ? channels.map((item, index) => {

                                        return (

                                            <div className="connected-channel-box" key={index}>
                                                <div className="connected-left-side">
                                                    <div className="channel-list-box-icon facebook-profile">
                                                        <img src={item.LinkedIn_picture} alt="facebook" />
                                                        <div className="bdyGBn social-icon-view">
                                                            <FaLinkedin />
                                                        </div>
                                                    </div>
                                                    <div className="channel-list-box-content">
                                                        <p className="user-name-id">{item.LinkedIn_name}</p>
                                                        <p className="platform-name">{item.platform}</p>
                                                    </div>
                                                </div>
                                                <div className="connected-right-side">
                                                    <div className="connected-sign">
                                                        <FaCircleCheck />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    ) :
                                        <>
                                            <p>You dont have any connected channels</p>
                                        </>

                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>



            </div>



        </>
    )
}

export default Channels