import React, { useState, useRef, useEffect, useContext } from "react";

import { FaCircleCheck } from "react-icons/fa6";
import { FaAngleDown, FaAngleUp, FaFacebook, FaInstagram } from "react-icons/fa6";
import { Button } from "react-bootstrap";
import { socialAccountContext } from "../../contexts/context/socialAccountContext";
import useSocialChannels from "../../contexts/actions/useSocialChannels";


import axios from "axios";
import useSocialChannelsDisconnect from "../../contexts/actions/useSocialChannelsDisconnect";

const API_URL = process.env.REACT_APP_API_URL;

const Channels = () => {

    const user = JSON.parse(localStorage.getItem('user'));

    const { connectedAccount, connectedAccountdispatch } = useContext(socialAccountContext);
    const { disConnectedChannels } = useSocialChannelsDisconnect()
    const { getConnectedChannels } = useSocialChannels();


    const [channels, setChannels] = useState([]);

    useEffect(() => {
        if (connectedAccount.accounts) {
            const facebookChannel = connectedAccount.accounts.filter(
                (channel) => channel.platform === "INSTAGRAM"&& channel.instagram_connected == true
            );
            setChannels(facebookChannel);
        }
    }, [connectedAccount]);


    useEffect(() => {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '1150846412593429',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v19.0'
            });


        };
    }, []);




    const handleLogin = async () => {
        var pagesData = [];
        window.FB.login(
            (response) => {
                console.log('Login response:', response);
                if (response.status === 'connected') {
                    const accessToken = response.authResponse.accessToken;
                    const userId = response.authResponse.userID;
                    managebackendlogin(accessToken, userId);
                   
                } else {
                    console.log('User cancelled login or didn\'t fully authorize the app');
                }
            },
            { scope: 'pages_manage_engagement,pages_manage_posts,instagram_basic,instagram_content_publish,pages_read_engagement,email,pages_show_list,business_management' }
        );
    };

    const saveDataToBackend = async (pagesData) => {
        try {
            const response = await fetch(`${API_URL}/socialposts/saveinstagramaccounts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ owner_id: user.owner_id, data: pagesData }),
            });

            const result = await response.json();

            if (result.status) {
                console.log('Pages data saved successfully')
                // alert('Pages data saved successfully');
            } else {
                console.log('Failed to save pages data');
            }
        } catch (error) {
            console.log('Error occurred while saving pages data:', error);
        }
    };


    const managebackendlogin = async (accessToken, userId) => {
        const fbdata = {
            accessToken,
            userId,
            owner_id: user.owner_id
        }

        const respose = await fetch(`${API_URL}/socialposts/handleinstagramlogin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(fbdata),

        })

        const result = await respose.json();

        if (result.status) {

            getConnectedChannels()

            alert('Connected successfuly');

        }
        else {
            alert('Failed to save pages data');
        }

    };



    const [isOpen, setIsOpen] = useState(false);

    const decoratedOnClick = () => {
        setIsOpen(!isOpen);
    };
    const handleDisconnect = async (social_ids) => {
        try {
            console.log("social_ids", social_ids)
            await disConnectedChannels("instagram", social_ids);
            getConnectedChannels(); // Refresh the connected channels
        } catch (error) {
            console.error("Error disconnecting:", error);
        }
    };
    // console.log("connectedAccount.accounts ",connectedAccount.accounts );
    return (

        <>
            <div className="channel-list">
                <div className="channel-box-wrapper">
                    <div className={`fQzPXW accordion-header ${isOpen ? 'active' : ''}`}>
                        <div className="channel-list-item">
                            <div className="channel-left-side">
                                <div className="social-icon">
                                    <img src={`${API_URL}/channels/1690434489531-instagram.png`} alt="facebook" />

                                </div>
                                <div className="social-details">
                                    <div className="social-name d-flex">
                                        <h4 className="m-0">Instagram</h4>
                                    </div>
                                    <div className="social-description">
                                        Business or Creators Account
                                    </div>
                                </div>
                            </div>
                            <div className="channel-right-side">
                            {channels.length > 0 ? (
                                    <Button onClick={() => handleDisconnect(channels[0]._id)} className="btn btn-sm float-right connect-user-btn">Disconnect</Button>
                                ) : (
                                    <Button onClick={handleLogin} className="btn btn-sm float-right connect-user-btn">Connect</Button>
                                )}
                                <div className="channel-right-side-btn bPqQjn">
                                    <Button onClick={decoratedOnClick} className="btn transparent-btn">
                                        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isOpen && (
                        <div className="accordion-collapse">
                            <div className="accordion-body-content">
                                <div className="connected-channels">
                                    <h3 className="accordion-title">Connected channels</h3>
                                    {channels ? channels.map((item, index) => {

                                        return (

                                            <div className="connected-channel-box" key={index}>
                                                <div className="connected-left-side">
                                                    <div className="channel-list-box-icon facebook-profile">
                                                        <img src={item.instagram_business_account_profile_picture_url} alt="facebook" />
                                                        <div className="bdyGBn social-icon-view">
                                                            <FaFacebook />
                                                        </div>
                                                    </div>
                                                    <div className="channel-list-box-content">
                                                        <p className="user-name-id">{item.instagram_business_account_name}</p>
                                                        <p className="platform-name">{item.platform}</p>
                                                    </div>
                                                </div>
                                                <div className="connected-right-side">
                                                    <div className="connected-sign">
                                                        <FaCircleCheck />
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    }

                                    ) :
                                        <>
                                            <p>You dont have any connected channels</p>
                                        </>

                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>

        </>
    )
}

export default Channels